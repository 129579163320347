import { AnchorTabs } from '@loveholidays/design-system';
import React, { MutableRefObject } from 'react';

import { AnchorTabsComponent as AnchorTabsComponentType } from '@AuroraTypes';

interface AnchorTabsComponentProps extends AnchorTabsComponentType {
  containerRef: MutableRefObject<HTMLDivElement>;
}

export const AnchorTabsComponent: React.FC<AnchorTabsComponentProps> = ({
  anchorTabs,
  containerRef,
}) => (
  <AnchorTabs
    containerRef={containerRef}
    anchors={anchorTabs.anchors}
    id="AnchorTabs"
  />
);
